<template>
  <div class="alert alert-warning" role="alert">
    <!-- this part uses a href rather than router.push calls so we can have a sane behavior on pressing back in the browser -->
    The redeemed coupon is only valid for select products. Please click <a :href="props.couponPlanPath" class="alert-link text-primary">here</a> to switch to:<br>
    {{ props.couponPlanName }}<br>
  </div>
</template>
<script setup>
  const props = defineProps({
    couponPlanPath: { type: String, required: true },
    couponPlanName: { type: String, required: true },
    couponCode: { type: String, required: true },
  });
</script>
